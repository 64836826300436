<template>
  <div class="home mt-0">

    <div class="columns buttons are-large">
      <div class="column is-full ">
        <router-link to="/alerts"><button class="button is-success is-fullwidth" >Shift Alerts</button></router-link>
      </div> 
      <div class="column is-full ">
        <router-link to="/shifts"><button class="button is-info is-fullwidth" >Booked Shifts</button></router-link>
      </div> 
      <div class="column is-full ">
        <router-link to="/timesheets"><button class="button is-info is-fullwidth" >Complete Timesheets</button></router-link>
      </div>  
      <div class="column is-full ">
        <router-link to="/availability"><button class="button is-info is-fullwidth" >Update Availability</button></router-link>
      </div>  
      <div class="column is-full ">
        <button class="button is-info is-fullwidth" >Training</button>
      </div> 
      <div class="column is-full ">
        <router-link to="/downloads"><button class="button is-info is-fullwidth" >Downloads</button></router-link>
      </div>
      <div class="column is-full ">
        <button class="button is-info is-fullwidth" >Policies</button>
      </div>  
      <div class="column is-full ">
        <button class="button is-info is-fullwidth" >Contact Us</button>
      </div>
      
      <div class="column is-full"><button class="button is-danger is-fullwidth" @click="logout()">Logout</button></div>
        
      </div>

      
    
  </div>
</template>

<script>

export default {
  name: 'Home',
  components: {
    
  },
  data: function () {
        return {
                
                API_NAME: process.env.VUE_APP_API_NAME,
                accessToken: '',
               
        }
    },
  methods: {
          logout() {
              if(confirm('Are you sure you want to logout ?'))
              {
                  //this.$auth.logout({
                  //returnTo: window.location.origin
                //});
              }
      }
    },
    created() {
          this.getAccessToken()
            .then(() => { 
              
            });
          
        },
}
</script>
