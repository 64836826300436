import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
//import { authGuard } from "../auth/authGuard";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {layout: "fullwidth"},
    //beforeEnter: authGuard
    
  },
  {
    path: '/downloads',
    name: 'Downloads',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "downloads" */ '../components/Downloads.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/shifts',
    name: 'Shifts',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "shifts" */ '../components/Shifts.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/alerts',
    name: 'Alerts',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "shifts" */ '../components/Alerts.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/availability',
    name: 'Alerts',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "shifts" */ '../components/Availability.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/view/:id',
    name: 'ViewApplication',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/ViewApplication.vue'),
    //beforeEnter: authGuard
  },
  {
    path: '/reference/:id',
    name: 'ViewApplication',
    meta: {layout: "fullwidth"},
    component: () => import(/* webpackChunkName: "applications" */ '../components/AddReference.vue'),
    //beforeEnter: authGuard
  },
  
  
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
